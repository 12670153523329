import { computed } from "@vue/composition-api";
import { useFind } from "feathers-vuex";
import Vue from "vue";

import { findAllMotRequirements } from "@/store/queries/mot-requirements";

const { MotRequirement } = Vue.$FeathersVuex.api;

export function useMotRequirementList() {
  const motRequirementsParams = computed(findAllMotRequirements);

  const {
    items: motRequirements,
    isPending: motRequirementsArePending,
    haveLoaded: motRequirementsHaveLoaded
  } = useFind({
    model: MotRequirement,
    params: motRequirementsParams.value,
    qid: "motRequirementsList"
  });

  return {
    motRequirements,
    motRequirementsArePending,
    motRequirementsHaveLoaded
  };
}
