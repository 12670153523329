<script>
  import {
    mdiFilterOutline,
    mdiMagnify,
    mdiMapMarkerOutline,
    mdiRefresh
  } from "@mdi/js";
  import { defineComponent, onMounted, ref, watch } from "@vue/composition-api";
  import Vue from "vue";

  import AppLoading from "@/components/base/loading/AppLoading.vue";
  import Breadcrumbs from "@/components/common/breadcrumbs/Breadcrumbs.vue";
  import { useProjectListBreadcrumbs } from "@/components/common/breadcrumbs/useBreadcrumbs";
  // components
  import FilterMenu from "@/components/common/filter-menu/FilterMenu.vue";
  import { resolveOrganizationLogo } from "@/composables/useOrganizations";
  import { usePagination } from "@/composables/usePagination";
  import { formatCurrency, formatDate } from "@/helpers/filter";
  import { projectStatusColor } from "@/helpers/statusColors";
  import useVuetify from "@/helpers/vuetify";
  import { store } from "@/store";
  import ProjectAdd from "@/views/apps/project/project-add/ProjectAdd.vue";
  import { useProjectAddDialog } from "@/views/apps/project/useProject";

  import { useProjectDatatable, useProjectList } from "./useProjectList";

  export default defineComponent({
    components: {
      FilterMenu,
      Breadcrumbs,
      ProjectAdd,
      AppLoading
    },
    setup() {
      const filterText = ref(null);
      const {
        latestQuery,
        isPending,
        haveLoaded,
        findProjects,
        projectsParams
      } = useProjectList();
      const { selectedNumberOfItemsPerPage, page, options } = usePagination();
      const { headers } = useProjectDatatable();
      const { breadcrumbs } = useProjectListBreadcrumbs();
      const { rootThemeClasses } = useVuetify();
      const { Project } = Vue.$FeathersVuex.api;

      const expanded = ref([]);

      // Format date as MM/DD/YYYY
      const proceedDateFormat = {
        month: "2-digit",
        day: "2-digit",
        year: "numeric"
      };
      const resolvePercentBudgetColor = (prctBudgetRemaining) => {
        if (prctBudgetRemaining <= 0) return "error";
        if (prctBudgetRemaining > 0 && prctBudgetRemaining <= 30)
          return "warning";
        if (prctBudgetRemaining > 30) return "success";
      };

      // Add new project shell
      const {
        isProjectAddDialogOpen,
        projectClone,
        createItem,
        processFormData,
        closeProjectAddDialog
      } = useProjectAddDialog();

      //const loading = ref(false);

      watch(options, (value) => {
        store.dispatch("projects/updatePagination", value);
      });

      watch(filterText, (value) => {
        store.dispatch("projects/filterText", value);
      });

      onMounted(async () => {
        await findProjects(projectsParams);
      });

      Project.on("created", async () => {
        await findProjects(projectsParams);
      });

      const refreshDataTable = async () => {
        await findProjects(projectsParams);
      };

      return {
        filterText,
        // useProjectList

        isPending,
        haveLoaded,
        latestQuery,
        findProjects,
        projectsParams,
        refreshDataTable,
        // usePagination
        selectedNumberOfItemsPerPage,
        page,
        options,

        // useProjectDatatable
        headers,

        // useProjectListBreadcrumbs
        breadcrumbs,

        expanded,
        rootThemeClasses,
        projectStatusColor,
        proceedDateFormat,
        formatDate,
        formatCurrency,
        resolveOrganizationLogo,
        resolvePercentBudgetColor,

        // useProjectAddDialog
        isProjectAddDialogOpen,
        projectClone,
        createItem,
        processFormData,
        closeProjectAddDialog,

        // icons
        icons: {
          mdiMapMarkerOutline,
          mdiMagnify,
          mdiFilterOutline,
          mdiRefresh
        }
      };
    }
  });
</script>

<template>
  <div v-if="haveLoaded">
    <breadcrumbs class="mb-5" :items="breadcrumbs" />
    <v-card id="invoice-list" flat>
      <v-card-text class="d-flex align-center justify-space-between flex-wrap">
        <!-- create contract -->
        <div class="d-flex title">All Projects</div>
        <div class="d-flex">
          <v-btn small color="primary" class="me-3" @click="createItem('new')">
            <span>Add Project</span>
          </v-btn>

          <filter-menu />
          <v-btn
            small
            outlined
            color="secondary"
            class="ml-3"
            @click="refreshDataTable()"
          >
            <v-icon>{{ icons.mdiRefresh }}</v-icon>
          </v-btn>
        </div>
      </v-card-text>

      <!-- data table -->
      <v-data-table
        :headers="headers"
        :items="latestQuery.response.data"
        :options.sync="options"
        :server-items-length="latestQuery.response.total"
        :loading="isPending"
        loading-text="Loading... Please wait"
        item-key="fulcrum_id"
        disable-sort
        :expanded.sync="expanded"
        show-expand
        :footer-props="{
          itemsPerPageOptions: [5, 10, 25, 50]
        }"
      >
        <!-- name -->
        <template #[`item.proceed_name`]="{ item }">
          <div class="d-flex align-center">
            <div class="v-avatar-group" :class="rootThemeClasses">
              <v-avatar
                :color="item.design_firm_record ? '' : 'primary'"
                :class="
                  item.design_firm_record
                    ? ''
                    : 'v-avatar-light-bg primary--text'
                "
                size="32"
              >
                <v-img
                  v-if="item.design_firm_record"
                  :src="resolveOrganizationLogo(item.design_firm_record)"
                ></v-img>
                <span v-else>{{
                  item.proceed_name.slice(0, 2).toUpperCase()
                }}</span>
              </v-avatar>
              <v-avatar
                :color="item.contractor_record ? '' : 'primary'"
                :class="
                  item.contractor_record
                    ? ''
                    : 'v-avatar-light-bg primary--text'
                "
                size="32"
              >
                <v-img
                  v-if="item.contractor_record"
                  :src="resolveOrganizationLogo(item.contractor_record)"
                ></v-img>
                <span v-else>{{
                  item.proceed_name.slice(0, 2).toUpperCase()
                }}</span>
              </v-avatar>
            </div>
            <div v-if="item.fulcrum_id" class="d-flex flex-column ms-3">
              <router-link
                :to="{
                  name: 'apps-project-view',
                  params: {
                    project: item.fulcrum_id
                  }
                }"
                class="primary--text font-weight-semibold text-truncate cursor-pointer text-decoration-none"
              >
                {{ item.proceed_num }}
              </router-link>
              <small class="secondary--text">{{ item.proceed_name }}</small>
            </div>
          </div>
        </template>

        <!-- status -->
        <template #[`item.status`]="{ item }">
          <v-chip
            small
            :color="projectStatusColor(item.status)"
            :class="`${projectStatusColor(item.status)}--text`"
            class="v-chip-light-bg"
          >
            {{ item.status }}
          </v-chip>
        </template>

        <!-- lead inspector -->
        <template #[`item.inspector_nm`]="{ item }">
          <span class="secondary--text">
            {{ item.inspector_nm }}
          </span>
        </template>

        <!-- project type -->
        <template #[`item.project_type`]="{ item }">
          <span class="secondary--text">
            {{ item.project_type }}
          </span>
        </template>

        <!-- proceed date -->
        <template #[`item.proceed_date`]="{ item }">
          <span v-if="item.proceed_date" class="secondary--text">
            {{ formatDate(item.proceed_date, proceedDateFormat) }}
          </span>
          <span v-else class="secondary--text font-weight-medium mb-0"
            >---</span
          >
        </template>

        <!-- Budget Remaining -->
        <template #[`item.budget_remaining`]="{ item }">
          <p
            v-if="item.budget_remaining === '---'"
            class="secondary--text font-weight-medium mb-0"
          >
            ---
          </p>
          <p
            v-else
            :class="
              item.budget_remaining < 0
                ? 'error--text font-weight-medium mb-1'
                : 'secondary--text font-weight-medium mb-1'
            "
          >
            {{ formatCurrency(item.budget_remaining) }}
          </p>

          <v-progress-linear
            :value="item.prct_budget_remaining"
            :color="resolvePercentBudgetColor(item.prct_budget_remaining)"
          ></v-progress-linear>
        </template>

        <!-- expanded data -->
        <template #expanded-item="{ headers, item }">
          <td :colspan="headers.length" class="pa-3">
            <v-card class="pa-3" flat>
              <h4 class="font-weight-semibold text-no-wrap my-1">
                Associated Permits
              </h4>
              <div v-for="site in item.site_record" :key="site.fulcrum_id">
                <div class="d-flex justify-space-between align-center">
                  <div class="d-flex flex-column my-2">
                    <span class="d-block font-weight-semibold primary--text">
                      <router-link
                        :to="{
                          name: 'apps-construction-record-view',
                          params: {
                            project: site.project_record,
                            record: site.fulcrum_id
                          }
                        }"
                        class="primary--text font-weight-semibold text-truncate cursor-pointer text-decoration-none"
                      >
                        {{ site.permit_num }}
                      </router-link>
                    </span>
                    <small class="text-truncate">{{ site.permit_desc }}</small>
                  </div>
                </div>
              </div>
            </v-card>
          </td>
        </template>
      </v-data-table>
    </v-card>
    <project-add
      :is-project-add-dialog-open.sync="isProjectAddDialogOpen"
      :create-item="projectClone"
      @save="processFormData"
      @close-dialog="closeProjectAddDialog"
    ></project-add>
  </div>
  <div v-else>
    <app-loading></app-loading>
  </div>
</template>
