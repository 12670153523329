import { computed } from "@vue/composition-api";
import { useFind } from "feathers-vuex";
import Vue from "vue";

import { findAllContractorLists } from "@/store/queries/contractor-lists";

const { ContractorList } = Vue.$FeathersVuex.api;

export function useContractorListLookup() {
  const contractorListsParams = computed(findAllContractorLists);

  const {
    items: contractorLists,
    isPending: contractorListsArePending,
    haveLoaded: contractorListsHaveLoaded
  } = useFind({
    model: ContractorList,
    params: contractorListsParams.value,
    qid: "contractorListLookupList"
  });

  /**
   * We need to get the following structure:
   *  contractorLists = [
   *    { header: 'Contractor Name 1'},
   *    { contractor_name: 'Byers' list_year: '2018', status: 'Active', etc...},
   *    { contractor_name: 'Byers' list_year: '2016', status: 'Active', etc...},
   *    { contractor_name: 'Byers' list_year: '1999', status: 'Active', etc...},
   *    { header: 'Contractor Name 2'},
   *    { contractor_name: 'Kriss' list_year: '2018', status: 'Active', etc...},
   *    { contractor_name: 'Kriss' list_year: '2016', status: 'Active', etc...},
   *    { contractor_name: 'Kriss' list_year: '1999', status: 'Active', etc...},
   *  ];
   *
   */

  const groupedContractorLists = computed(() => {
    return useGroupedContractorListByContractor(contractorLists);
  });

  function useGroupedContractorListByContractor(contractorLists) {
    // Create an intermediate object to hold categories
    const groups = {};

    // Create array for each group to main subgroup list
    contractorLists.value.forEach((x) => {
      // create empty object if it doesn't exist
      groups[x.list_year] = groups[x.list_year] || {
        name: x.list_year,
        list: []
      };

      groups[x.list_year].list.push(x);
    });

    // The flattened list of items that holds items as well as unique headers
    const flattened = [];

    // Iterate over all the unique cetegories and
    // then flatten into a list that v-select needs
    Object.keys(groups).forEach((categoryId) => {
      const category = groups[categoryId];
      const categoryName = category.name;

      // Create a group
      flattened.push({ header: categoryName });

      // Add all the items followed by category header
      flattened.push(...category.list);
    });

    return flattened;
  }

  const distinctActiveContractorLists = computed(() => {
    return useDistinctActiveContractorLists(contractorLists);
  });

  function useDistinctActiveContractorLists(contractorLists) {
    var distinctContractors = [];

    contractorLists.value.filter(function (contractor) {
      var i = distinctContractors.findIndex(
        (x) => x.contractor_record == contractor.contractor_record
      );
      if (i <= -1) {
        distinctContractors.push(contractor);
      }
      return null;
    });

    return distinctContractors;
  }

  return {
    contractorLists,
    contractorListsArePending,
    contractorListsHaveLoaded,
    groupedContractorLists,
    distinctActiveContractorLists
  };
}
