import { ref } from "@vue/composition-api";

export function useAssignedDatePicker() {
  const assignedDate = ref(null);
  const assignedDateMenu = ref(false);

  return {
    assignedDate,
    assignedDateMenu
  };
}

export function useHandOffDatePicker() {
  const handOffDate = ref(null);
  const handOffDateMenu = ref(false);

  return {
    handOffDate,
    handOffDateMenu
  };
}

export function useProceedDatePicker() {
  const proceedDate = ref(null);
  const proceedDateMenu = ref(false);

  return {
    proceedDate,
    proceedDateMenu
  };
}

export function useRainStartDatePicker() {
  const rainStartDate = ref(null);
  const rainStartDateMenu = ref(false);

  const resolveMinRainStartDate = (project) => {
    const { proceed_date } = project;
    let date = new Date(proceed_date);
    date.setDate(date.getDate() + 1);
    return date.toISOString().slice(0, 10);
  };

  return {
    rainStartDate,
    rainStartDateMenu,
    resolveMinRainStartDate
  };
}

export function useDayStartTimePicker() {
  const dayStartTime = ref(null);
  const dayStartTimeMenu = ref(false);

  return {
    dayStartTime,
    dayStartTimeMenu
  };
}

export function useDayEndTimePicker() {
  const dayEndTime = ref(null);
  const dayEndTimeMenu = ref(false);

  return {
    dayEndTime,
    dayEndTimeMenu
  };
}

export function useNightStartTimePicker() {
  const nightStartTime = ref(null);
  const nightStartTimeMenu = ref(false);

  return {
    nightStartTime,
    nightStartTimeMenu
  };
}

export function useNightEndTimePicker() {
  const nightEndTime = ref(null);
  const nightEndTimeMenu = ref(false);

  return {
    nightEndTime,
    nightEndTimeMenu
  };
}

export function useStartDatePicker() {
  const startDate = ref(null);
  const startDateMenu = ref(false);

  return {
    startDate,
    startDateMenu
  };
}

export function useEndDatePicker() {
  const endDate = ref(null);
  const endDateMenu = ref(false);

  return {
    endDate,
    endDateMenu
  };
}

export function useCloseOutDatePicker() {
  const closeOutDate = ref(null);
  const closeOutDateMenu = ref(false);

  return {
    closeOutDate,
    closeOutDateMenu
  };
}

export function useCalculatedDates() {
  const oneDay = 86400000; //milliseconds in a day

  const resolveHandOffToNtpDate = (project) => {
    const { proceed_date, hand_off_date } = project;
    if (!proceed_date || !hand_off_date) return null;
    return Math.abs(new Date(proceed_date) - new Date(hand_off_date)) / oneDay;
  };

  const resolveDurationDays = (project) => {
    const { expedited, initial_amount } = project;

    return expedited === "Yes"
      ? 25
      : initial_amount <= 100000.01
      ? 40
      : initial_amount > 200000
      ? 90
      : initial_amount > 100000
      ? 60
      : 0;
  };

  const resolveEstimateStartDate = (project) => {
    const { proceed_date } = project;
    if (!proceed_date) return null;
    let date = new Date(proceed_date);
    date.setDate(date.getDate() + 14);

    return date.toISOString().slice(0, 10);
  };

  const resolveEstimateEndDate = (project) => {
    const { proceed_date, duration_days } = project;
    if (!proceed_date || !duration_days) return null;
    const dayOfTheWeek = new Date(proceed_date).getDay();
    const daysToAdd =
      dayOfTheWeek === 6
        ? -1 + duration_days + ((duration_days + 4) / 5) * 2
        : duration_days + ((duration_days + dayOfTheWeek - 2) / 5) * 2;

    let date = new Date(proceed_date);
    date.setDate(date.getDate() + daysToAdd);

    return date.toISOString().slice(0, 10);
  };

  return {
    resolveHandOffToNtpDate,
    resolveDurationDays,
    resolveEstimateStartDate,
    resolveEstimateEndDate
  };
}
