var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.haveLoaded)?_c('div',[_c('breadcrumbs',{staticClass:"mb-5",attrs:{"items":_vm.breadcrumbs}}),_c('v-card',{attrs:{"id":"invoice-list","flat":""}},[_c('v-card-text',{staticClass:"d-flex align-center justify-space-between flex-wrap"},[_c('div',{staticClass:"d-flex title"},[_vm._v("All Projects")]),_c('div',{staticClass:"d-flex"},[_c('v-btn',{staticClass:"me-3",attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.createItem('new')}}},[_c('span',[_vm._v("Add Project")])]),_c('filter-menu'),_c('v-btn',{staticClass:"ml-3",attrs:{"small":"","outlined":"","color":"secondary"},on:{"click":function($event){return _vm.refreshDataTable()}}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiRefresh))])],1)],1)]),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.latestQuery.response.data,"options":_vm.options,"server-items-length":_vm.latestQuery.response.total,"loading":_vm.isPending,"loading-text":"Loading... Please wait","item-key":"fulcrum_id","disable-sort":"","expanded":_vm.expanded,"show-expand":"","footer-props":{
        itemsPerPageOptions: [5, 10, 25, 50]
      }},on:{"update:options":function($event){_vm.options=$event},"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.proceed_name",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"v-avatar-group",class:_vm.rootThemeClasses},[_c('v-avatar',{class:item.design_firm_record
                  ? ''
                  : 'v-avatar-light-bg primary--text',attrs:{"color":item.design_firm_record ? '' : 'primary',"size":"32"}},[(item.design_firm_record)?_c('v-img',{attrs:{"src":_vm.resolveOrganizationLogo(item.design_firm_record)}}):_c('span',[_vm._v(_vm._s(item.proceed_name.slice(0, 2).toUpperCase()))])],1),_c('v-avatar',{class:item.contractor_record
                  ? ''
                  : 'v-avatar-light-bg primary--text',attrs:{"color":item.contractor_record ? '' : 'primary',"size":"32"}},[(item.contractor_record)?_c('v-img',{attrs:{"src":_vm.resolveOrganizationLogo(item.contractor_record)}}):_c('span',[_vm._v(_vm._s(item.proceed_name.slice(0, 2).toUpperCase()))])],1)],1),(item.fulcrum_id)?_c('div',{staticClass:"d-flex flex-column ms-3"},[_c('router-link',{staticClass:"primary--text font-weight-semibold text-truncate cursor-pointer text-decoration-none",attrs:{"to":{
                name: 'apps-project-view',
                params: {
                  project: item.fulcrum_id
                }
              }}},[_vm._v(" "+_vm._s(item.proceed_num)+" ")]),_c('small',{staticClass:"secondary--text"},[_vm._v(_vm._s(item.proceed_name))])],1):_vm._e()])]}},{key:"item.status",fn:function(ref){
              var item = ref.item;
return [_c('v-chip',{staticClass:"v-chip-light-bg",class:((_vm.projectStatusColor(item.status)) + "--text"),attrs:{"small":"","color":_vm.projectStatusColor(item.status)}},[_vm._v(" "+_vm._s(item.status)+" ")])]}},{key:"item.inspector_nm",fn:function(ref){
              var item = ref.item;
return [_c('span',{staticClass:"secondary--text"},[_vm._v(" "+_vm._s(item.inspector_nm)+" ")])]}},{key:"item.project_type",fn:function(ref){
              var item = ref.item;
return [_c('span',{staticClass:"secondary--text"},[_vm._v(" "+_vm._s(item.project_type)+" ")])]}},{key:"item.proceed_date",fn:function(ref){
              var item = ref.item;
return [(item.proceed_date)?_c('span',{staticClass:"secondary--text"},[_vm._v(" "+_vm._s(_vm.formatDate(item.proceed_date, _vm.proceedDateFormat))+" ")]):_c('span',{staticClass:"secondary--text font-weight-medium mb-0"},[_vm._v("---")])]}},{key:"item.budget_remaining",fn:function(ref){
              var item = ref.item;
return [(item.budget_remaining === '---')?_c('p',{staticClass:"secondary--text font-weight-medium mb-0"},[_vm._v(" --- ")]):_c('p',{class:item.budget_remaining < 0
              ? 'error--text font-weight-medium mb-1'
              : 'secondary--text font-weight-medium mb-1'},[_vm._v(" "+_vm._s(_vm.formatCurrency(item.budget_remaining))+" ")]),_c('v-progress-linear',{attrs:{"value":item.prct_budget_remaining,"color":_vm.resolvePercentBudgetColor(item.prct_budget_remaining)}})]}},{key:"expanded-item",fn:function(ref){
              var headers = ref.headers;
              var item = ref.item;
return [_c('td',{staticClass:"pa-3",attrs:{"colspan":headers.length}},[_c('v-card',{staticClass:"pa-3",attrs:{"flat":""}},[_c('h4',{staticClass:"font-weight-semibold text-no-wrap my-1"},[_vm._v(" Associated Permits ")]),_vm._l((item.site_record),function(site){return _c('div',{key:site.fulcrum_id},[_c('div',{staticClass:"d-flex justify-space-between align-center"},[_c('div',{staticClass:"d-flex flex-column my-2"},[_c('span',{staticClass:"d-block font-weight-semibold primary--text"},[_c('router-link',{staticClass:"primary--text font-weight-semibold text-truncate cursor-pointer text-decoration-none",attrs:{"to":{
                        name: 'apps-construction-record-view',
                        params: {
                          project: site.project_record,
                          record: site.fulcrum_id
                        }
                      }}},[_vm._v(" "+_vm._s(site.permit_num)+" ")])],1),_c('small',{staticClass:"text-truncate"},[_vm._v(_vm._s(site.permit_desc))])])])])})],2)],1)]}}],null,true)})],1),_c('project-add',{attrs:{"is-project-add-dialog-open":_vm.isProjectAddDialogOpen,"create-item":_vm.projectClone},on:{"update:isProjectAddDialogOpen":function($event){_vm.isProjectAddDialogOpen=$event},"update:is-project-add-dialog-open":function($event){_vm.isProjectAddDialogOpen=$event},"save":_vm.processFormData,"close-dialog":_vm.closeProjectAddDialog}})],1):_c('div',[_c('app-loading')],1)}
var staticRenderFns = []

export { render, staticRenderFns }