/**
 * @file Contractor Lists
 * @author Anne Canoune
 * @version 0.1.0
 * @description All of the queries associated with the Contractor Lists service
 */

/**
 * Find All Contractor Lists
 * @description Returns all contractor lists.
 *
 *
 * @returns {object}
 */

function findAllContractorLists() {
  return {
    query: {
      $select: [
        "fulcrum_id",
        "status",
        "contractor_name",
        "contractor_record",
        "short_list_record",
        "list_year"
      ],
      status: "Active",
      $sort: {
        list_year: -1,
        contractor_name: 1
      }
    }
  };
}
exports.findAllContractorLists = findAllContractorLists;
